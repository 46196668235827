/* Scroll Animation Section */
.container {
  width: 100%;
  display: initial;
}
.posRela{
  position: relative;
}
@media screen and (max-width:1200px) {
  .Animation-svg {
    display: none;
  }
}
.firstSection {
  position: relative;
padding:  0 0 0;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.Animation-svg {
  width: 100%;
}

/* Become A Driver Section */
#become-driver {
  width: 100%;
  background: #eefff6;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
