@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
::-webkit-scrollbar {
  width: 0;
}
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif !important;
  font-style: normal;
}
.navul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.navul .navli {
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .navul .navli {
    font-size: 16px;
  }
}
@media screen and (max-width: 1705px) {
  .become-driver{
    margin-top: -50px !important;
  }
}
@media screen and (max-width: 1580px) {
  .become-driver{
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 1540px) {
  .become-driver{
    margin-top: 200px !important;
  }
}
@media screen and (max-width: 1535px) {
  .become-driver{
    margin-top: -300px !important;
  }
}
@media screen and (max-width: 1320px) {
  .become-driver{
    margin-top: -100px !important;
  }
}
@media screen and (max-width: 1220px) {
  .become-driver{
    margin-top: 150px !important;
  }
}
@media screen and (max-width: 900px) {
  .become-driver {
    padding:  30px 30px 30px 30px !important
  }
  .about{
    padding:  30px 15px 30px 15px !important
  }
}
@media screen and (max-width: 1024px) {
  .navul .navli {
    font-size: 16px;
  }
  .become-driver {
    padding:  30px 30px 30px 30px !important
  }
  .about{
    padding:  30px 15px 30px 15px !important
  }
}
.imageback{
  background-image: url("./Assets/Images/greenbackground.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.car-image {
  position: absolute;
  top: 0;
  animation: slide 10s linear infinite;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#car-images-box {
  position: absolute;
  top: 0;
  width: 100%;
  background-image: url("./Assets//Images/threecarsroad.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.roadsvg{
  min-height: 100vh !important;
  border: #07e77a !important;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slide {
  0% {
    background-image: url("./Assets/Images/cars/1.svg");
  }
  0.4348% {
    background-image: url("./Assets/Images/cars/2.svg");
  }
  0.8696% {
    background-image: url("./Assets/Images/cars/3.svg");
  }
  1.3043% {
    background-image: url("./Assets/Images/cars/4.svg");
  }
  1.7391% {
    background-image: url("./Assets/Images/cars/5.svg");
  }
  2.1739% {
    background-image: url("./Assets/Images/cars/6.svg");
  }
  2.6087% {
    background-image: url("./Assets/Images/cars/7.svg");
  }
  3.0435% {
    background-image: url("./Assets/Images/cars/8.svg");
  }
  3.4783% {
    background-image: url("./Assets/Images/cars/9.svg");
  }
  3.913% {
    background-image: url("./Assets/Images/cars/10.svg");
  }
  4.3478% {
    background-image: url("./Assets/Images/cars/11.svg");
  }
  4.7826% {
    background-image: url("./Assets/Images/cars/12.svg");
  }
  5.2174% {
    background-image: url("./Assets/Images/cars/13.svg");
  }
  5.6522% {
    background-image: url("./Assets/Images/cars/14.svg");
  }
  6.087% {
    background-image: url("./Assets/Images/cars/15.svg");
  }
  6.5217% {
    background-image: url("./Assets/Images/cars/16.svg");
  }
  6.9565% {
    background-image: url("./Assets/Images/cars/17.svg");
  }
  7.3913% {
    background-image: url("./Assets/Images/cars/18.svg");
  }
  7.8261% {
    background-image: url("./Assets/Images/cars/19.svg");
  }
  8.2609% {
    background-image: url("./Assets/Images/cars/20.svg");
  }
  8.6957% {
    background-image: url("./Assets/Images/cars/21.svg");
  }
  9.1304% {
    background-image: url("./Assets/Images/cars/22.svg");
  }
  9.5652% {
    background-image: url("./Assets/Images/cars/23.svg");
  }
  10% {
    background-image: url("./Assets/Images/cars/24.svg");
  }
  10.4348% {
    background-image: url("./Assets/Images/cars/25.svg");
  }
  10.8696% {
    background-image: url("./Assets/Images/cars/26.svg");
  }
  11.3043% {
    background-image: url("./Assets/Images/cars/27.svg");
  }
  11.7391% {
    background-image: url("./Assets/Images/cars/28.svg");
  }
  12.1739% {
    background-image: url("./Assets/Images/cars/29.svg");
  }
  12.6087% {
    background-image: url("./Assets/Images/cars/30.svg");
  }
  13.0435% {
    background-image: url("./Assets/Images/cars/31.svg");
  }
  13.4783% {
    background-image: url("./Assets/Images/cars/32.svg");
  }
  13.913% {
    background-image: url("./Assets/Images/cars/33.svg");
  }
  14.3478% {
    background-image: url("./Assets/Images/cars/34.svg");
  }
  14.7826% {
    background-image: url("./Assets/Images/cars/35.svg");
  }
  15.2174% {
    background-image: url("./Assets/Images/cars/36.svg");
  }
  15.6522% {
    background-image: url("./Assets/Images/cars/37.svg");
  }
  16.087% {
    background-image: url("./Assets/Images/cars/38.svg");
  }
  16.5217% {
    background-image: url("./Assets/Images/cars/39.svg");
  }
  16.9565% {
    background-image: url("./Assets/Images/cars/40.svg");
  }
  17.3913% {
    background-image: url("./Assets/Images/cars/41.svg");
  }
  17.8261% {
    background-image: url("./Assets/Images/cars/42.svg");
  }
  18.2609% {
    background-image: url("./Assets/Images/cars/43.svg");
  }
  18.6957% {
    background-image: url("./Assets/Images/cars/44.svg");
  }
  19.1304% {
    background-image: url("./Assets/Images/cars/45.svg");
  }
  19.5652% {
    background-image: url("./Assets/Images/cars/46.svg");
  }
  20% {
    background-image: url("./Assets/Images/cars/47.svg");
  }
  20.4348% {
    background-image: url("./Assets/Images/cars/48.svg");
  }
  20.8696% {
    background-image: url("./Assets/Images/cars/49.svg");
  }
  21.3043% {
    background-image: url("./Assets/Images/cars/50.svg");
  }
  21.7391% {
    background-image: url("./Assets/Images/cars/51.svg");
  }
  22.1739% {
    background-image: url("./Assets/Images/cars/52.svg");
  }
  22.6087% {
    background-image: url("./Assets/Images/cars/53.svg");
  }
  23.0435% {
    background-image: url("./Assets/Images/cars/54.svg");
  }
  23.4783% {
    background-image: url("./Assets/Images/cars/55.svg");
  }
  23.913% {
    background-image: url("./Assets/Images/cars/56.svg");
  }
  24.3478% {
    background-image: url("./Assets/Images/cars/57.svg");
  }
  24.7826% {
    background-image: url("./Assets/Images/cars/58.svg");
  }
  25.2174% {
    background-image: url("./Assets/Images/cars/59.svg");
  }
  25.6522% {
    background-image: url("./Assets/Images/cars/60.svg");
  }
  26.087% {
    background-image: url("./Assets/Images/cars/61.svg");
  }
  26.5217% {
    background-image: url("./Assets/Images/cars/62.svg");
  }
  26.9565% {
    background-image: url("./Assets/Images/cars/63.svg");
  }
  27.3913% {
    background-image: url("./Assets/Images/cars/64.svg");
  }
  27.8261% {
    background-image: url("./Assets/Images/cars/65.svg");
  }
  28.2609% {
    background-image: url("./Assets/Images/cars/66.svg");
  }
  28.6957% {
    background-image: url("./Assets/Images/cars/67.svg");
  }
  29.1304% {
    background-image: url("./Assets/Images/cars/68.svg");
  }
  29.5652% {
    background-image: url("./Assets/Images/cars/69.svg");
  }
  30% {
    background-image: url("./Assets/Images/cars/70.svg");
  }
  30.4348% {
    background-image: url("./Assets/Images/cars/71.svg");
  }
  30.8696% {
    background-image: url("./Assets/Images/cars/72.svg");
  }
  31.3043% {
    background-image: url("./Assets/Images/cars/73.svg");
  }
  31.7391% {
    background-image: url("./Assets/Images/cars/74.svg");
  }
  32.1739% {
    background-image: url("./Assets/Images/cars/75.svg");
  }
  32.6087% {
    background-image: url("./Assets/Images/cars/76.svg");
  }
  33.0435% {
    background-image: url("./Assets/Images/cars/77.svg");
  }
  33.4783% {
    background-image: url("./Assets/Images/cars/78.svg");
  }
  33.913% {
    background-image: url("./Assets/Images/cars/79.svg");
  }
  34.3478% {
    background-image: url("./Assets/Images/cars/80.svg");
  }
  34.7826% {
    background-image: url("./Assets/Images/cars/81.svg");
  }
  35.2174% {
    background-image: url("./Assets/Images/cars/82.svg");
  }
  35.6522% {
    background-image: url("./Assets/Images/cars/83.svg");
  }
  36.087% {
    background-image: url("./Assets/Images/cars/84.svg");
  }
  36.5217% {
    background-image: url("./Assets/Images/cars/85.svg");
  }
  36.9565% {
    background-image: url("./Assets/Images/cars/86.svg");
  }
  37.3913% {
    background-image: url("./Assets/Images/cars/87.svg");
  }
  37.8261% {
    background-image: url("./Assets/Images/cars/88.svg");
  }
  38.2609% {
    background-image: url("./Assets/Images/cars/89.svg");
  }
  38.6957% {
    background-image: url("./Assets/Images/cars/90.svg");
  }
  39.1304% {
    background-image: url("./Assets/Images/cars/91.svg");
  }
  39.5652% {
    background-image: url("./Assets/Images/cars/92.svg");
  }
  40% {
    background-image: url("./Assets/Images/cars/93.svg");
  }
  40.4348% {
    background-image: url("./Assets/Images/cars/94.svg");
  }
  40.8696% {
    background-image: url("./Assets/Images/cars/95.svg");
  }
  41.3043% {
    background-image: url("./Assets/Images/cars/96.svg");
  }
  41.7391% {
    background-image: url("./Assets/Images/cars/97.svg");
  }
  42.1739% {
    background-image: url("./Assets/Images/cars/98.svg");
  }
  42.6087% {
    background-image: url("./Assets/Images/cars/99.svg");
  }
  43.0435% {
    background-image: url("./Assets/Images/cars/100.svg");
  }
  43.4783% {
    background-image: url("./Assets/Images/cars/101.svg");
  }
  43.913% {
    background-image: url("./Assets/Images/cars/102.svg");
  }
  44.3478% {
    background-image: url("./Assets/Images/cars/103.svg");
  }
  44.7826% {
    background-image: url("./Assets/Images/cars/104.svg");
  }
  45.2174% {
    background-image: url("./Assets/Images/cars/105.svg");
  }
  45.6522% {
    background-image: url("./Assets/Images/cars/106.svg");
  }
  46.087% {
    background-image: url("./Assets/Images/cars/107.svg");
  }
  46.5217% {
    background-image: url("./Assets/Images/cars/108.svg");
  }
  46.9565% {
    background-image: url("./Assets/Images/cars/109.svg");
  }
  47.3913% {
    background-image: url("./Assets/Images/cars/110.svg");
  }
  47.8261% {
    background-image: url("./Assets/Images/cars/111.svg");
  }
  48.2609% {
    background-image: url("./Assets/Images/cars/112.svg");
  }
  48.6957% {
    background-image: url("./Assets/Images/cars/113.svg");
  }
  49.1304% {
    background-image: url("./Assets/Images/cars/114.svg");
  }
  49.5652% {
    background-image: url("./Assets/Images/cars/115.svg");
  }
  50% {
    background-image: url("./Assets/Images/cars/116.svg");
  }
  50.4348% {
    background-image: url("./Assets/Images/cars/117.svg");
  }
  50.8696% {
    background-image: url("./Assets/Images/cars/118.svg");
  }
  51.3043% {
    background-image: url("./Assets/Images/cars/119.svg");
  }
  51.7391% {
    background-image: url("./Assets/Images/cars/120.svg");
  }
  52.1739% {
    background-image: url("./Assets/Images/cars/121.svg");
  }
  52.6087% {
    background-image: url("./Assets/Images/cars/122.svg");
  }
  53.0435% {
    background-image: url("./Assets/Images/cars/123.svg");
  }
  53.4783% {
    background-image: url("./Assets/Images/cars/124.svg");
  }
  53.913% {
    background-image: url("./Assets/Images/cars/125.svg");
  }
  54.3478% {
    background-image: url("./Assets/Images/cars/126.svg");
  }
  54.7826% {
    background-image: url("./Assets/Images/cars/127.svg");
  }
  55.2174% {
    background-image: url("./Assets/Images/cars/128.svg");
  }
  55.6522% {
    background-image: url("./Assets/Images/cars/129.svg");
  }
  56.087% {
    background-image: url("./Assets/Images/cars/130.svg");
  }
  56.5217% {
    background-image: url("./Assets/Images/cars/131.svg");
  }
  56.9565% {
    background-image: url("./Assets/Images/cars/132.svg");
  }
  57.3913% {
    background-image: url("./Assets/Images/cars/133.svg");
  }
  57.8261% {
    background-image: url("./Assets/Images/cars/134.svg");
  }
  58.2609% {
    background-image: url("./Assets/Images/cars/135.svg");
  }
  58.6957% {
    background-image: url("./Assets/Images/cars/136.svg");
  }
  59.1304% {
    background-image: url("./Assets/Images/cars/137.svg");
  }
  59.5652% {
    background-image: url("./Assets/Images/cars/138.svg");
  }
  60% {
    background-image: url("./Assets/Images/cars/139.svg");
  }
  60.4348% {
    background-image: url("./Assets/Images/cars/140.svg");
  }
  60.8696% {
    background-image: url("./Assets/Images/cars/141.svg");
  }
  61.3043% {
    background-image: url("./Assets/Images/cars/142.svg");
  }
  61.7391% {
    background-image: url("./Assets/Images/cars/143.svg");
  }
  62.1739% {
    background-image: url("./Assets/Images/cars/144.svg");
  }
  62.6087% {
    background-image: url("./Assets/Images/cars/145.svg");
  }
  63.0435% {
    background-image: url("./Assets/Images/cars/146.svg");
  }
  63.4783% {
    background-image: url("./Assets/Images/cars/147.svg");
  }
  63.913% {
    background-image: url("./Assets/Images/cars/148.svg");
  }
  64.3478% {
    background-image: url("./Assets/Images/cars/149.svg");
  }
  64.7826% {
    background-image: url("./Assets/Images/cars/150.svg");
  }
  65.2174% {
    background-image: url("./Assets/Images/cars/151.svg");
  }
  65.6522% {
    background-image: url("./Assets/Images/cars/152.svg");
  }
  66.087% {
    background-image: url("./Assets/Images/cars/153.svg");
  }
  66.5217% {
    background-image: url("./Assets/Images/cars/154.svg");
  }
  66.9565% {
    background-image: url("./Assets/Images/cars/155.svg");
  }
  67.3913% {
    background-image: url("./Assets/Images/cars/156.svg");
  }
  67.8261% {
    background-image: url("./Assets/Images/cars/157.svg");
  }
  68.2609% {
    background-image: url("./Assets/Images/cars/158.svg");
  }
  68.6957% {
    background-image: url("./Assets/Images/cars/159.svg");
  }
  69.1304% {
    background-image: url("./Assets/Images/cars/160.svg");
  }
  69.5652% {
    background-image: url("./Assets/Images/cars/161.svg");
  }
  70% {
    background-image: url("./Assets/Images/cars/162.svg");
  }
  70.4348% {
    background-image: url("./Assets/Images/cars/163.svg");
  }
  70.8696% {
    background-image: url("./Assets/Images/cars/164.svg");
  }
  71.3043% {
    background-image: url("./Assets/Images/cars/165.svg");
  }
  71.7391% {
    background-image: url("./Assets/Images/cars/166.svg");
  }
  72.1739% {
    background-image: url("./Assets/Images/cars/167.svg");
  }
  72.6087% {
    background-image: url("./Assets/Images/cars/168.svg");
  }
  73.0435% {
    background-image: url("./Assets/Images/cars/169.svg");
  }
  73.4783% {
    background-image: url("./Assets/Images/cars/170.svg");
  }
  73.913% {
    background-image: url("./Assets/Images/cars/171.svg");
  }
  74.3478% {
    background-image: url("./Assets/Images/cars/172.svg");
  }
  74.7826% {
    background-image: url("./Assets/Images/cars/173.svg");
  }
  75.2174% {
    background-image: url("./Assets/Images/cars/174.svg");
  }
  75.6522% {
    background-image: url("./Assets/Images/cars/175.svg");
  }
  76.087% {
    background-image: url("./Assets/Images/cars/176.svg");
  }
  76.5217% {
    background-image: url("./Assets/Images/cars/177.svg");
  }
  76.9565% {
    background-image: url("./Assets/Images/cars/178.svg");
  }
  77.3913% {
    background-image: url("./Assets/Images/cars/179.svg");
  }
  77.8261% {
    background-image: url("./Assets/Images/cars/180.svg");
  }
  78.2609% {
    background-image: url("./Assets/Images/cars/181.svg");
  }
  78.6957% {
    background-image: url("./Assets/Images/cars/182.svg");
  }
  79.1304% {
    background-image: url("./Assets/Images/cars/183.svg");
  }
  79.5652% {
    background-image: url("./Assets/Images/cars/184.svg");
  }
  80% {
    background-image: url("./Assets/Images/cars/185.svg");
  }
  80.4348% {
    background-image: url("./Assets/Images/cars/186.svg");
  }
  80.8696% {
    background-image: url("./Assets/Images/cars/187.svg");
  }
  81.3043% {
    background-image: url("./Assets/Images/cars/188.svg");
  }
  81.7391% {
    background-image: url("./Assets/Images/cars/189.svg");
  }
  82.1739% {
    background-image: url("./Assets/Images/cars/190.svg");
  }
  82.6087% {
    background-image: url("./Assets/Images/cars/191.svg");
  }
  83.0435% {
    background-image: url("./Assets/Images/cars/192.svg");
  }
  83.4783% {
    background-image: url("./Assets/Images/cars/193.svg");
  }
  83.913% {
    background-image: url("./Assets/Images/cars/194.svg");
  }
  84.3478% {
    background-image: url("./Assets/Images/cars/195.svg");
  }
  84.7826% {
    background-image: url("./Assets/Images/cars/196.svg");
  }
  85.2174% {
    background-image: url("./Assets/Images/cars/197.svg");
  }
  85.6522% {
    background-image: url("./Assets/Images/cars/198.svg");
  }
  86.087% {
    background-image: url("./Assets/Images/cars/199.svg");
  }
  86.5217% {
    background-image: url("./Assets/Images/cars/200.svg");
  }
  86.9565% {
    background-image: url("./Assets/Images/cars/201.svg");
  }
  87.3913% {
    background-image: url("./Assets/Images/cars/202.svg");
  }
  87.8261% {
    background-image: url("./Assets/Images/cars/203.svg");
  }
  88.2609% {
    background-image: url("./Assets/Images/cars/204.svg");
  }
  88.6957% {
    background-image: url("./Assets/Images/cars/205.svg");
  }
  89.1304% {
    background-image: url("./Assets/Images/cars/206.svg");
  }
  89.5652% {
    background-image: url("./Assets/Images/cars/207.svg");
  }
  90% {
    background-image: url("./Assets/Images/cars/208.svg");
  }
  90.4348% {
    background-image: url("./Assets/Images/cars/209.svg");
  }
  90.8696% {
    background-image: url("./Assets/Images/cars/210.svg");
  }
  91.3043% {
    background-image: url("./Assets/Images/cars/211.svg");
  }
  91.7391% {
    background-image: url("./Assets/Images/cars/212.svg");
  }
  92.1739% {
    background-image: url("./Assets/Images/cars/213.svg");
  }
  92.6087% {
    background-image: url("./Assets/Images/cars/214.svg");
  }
  93.0435% {
    background-image: url("./Assets/Images/cars/215.svg");
  }
  93.4783% {
    background-image: url("./Assets/Images/cars/216.svg");
  }
  93.913% {
    background-image: url("./Assets/Images/cars/217.svg");
  }
  94.3478% {
    background-image: url("./Assets/Images/cars/218.svg");
  }
  94.7826% {
    background-image: url("./Assets/Images/cars/219.svg");
  }
  95.2174% {
    background-image: url("./Assets/Images/cars/220.svg");
  }
  95.6522% {
    background-image: url("./Assets/Images/cars/221.svg");
  }
  96.087% {
    background-image: url("./Assets/Images/cars/222.svg");
  }
  96.5217% {
    background-image: url("./Assets/Images/cars/223.svg");
  }
  96.9565% {
    background-image: url("./Assets/Images/cars/224.svg");
  }
  97.3913% {
    background-image: url("./Assets/Images/cars/225.svg");
  }
  97.8261% {
    background-image: url("./Assets/Images/cars/226.svg");
  }
  98.2609% {
    background-image: url("./Assets/Images/cars/227.svg");
  }
  98.6957% {
    background-image: url("./Assets/Images/cars/228.svg");
  }
  99.1304% {
    background-image: url("./Assets/Images/cars/229.svg");
  }
  99.5652% {
    background-image: url("./Assets/Images/cars/230.svg");
  }
  100% {
    background-image: url("./Assets/Images/cars/1.svg");
  }
}

.cursor-Animated {
  position: fixed;
  background-color: #07e77a;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
}
.cursor-follower-Animated {
  position: fixed;
  border: 1px solid #07e77a;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: translate(2px, 2px);
}
